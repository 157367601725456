import React, { useState, Fragment, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { navigate, Link, useIntl, FormattedMessage } from "gatsby-plugin-intl";

import api from "../../service/api";
import * as constants from "../../app.constant";
import CheckboxField from "../../components/form/checkbox-field";
import InputField from "../../components/form/input-field";
import AppLayout from "../../components/shared/AppLayout";
import AppStepsIndicator from "../../components/shared/AppStepsIndicator";
import AppSmsModal from "../../components/shared/AppSmsModal";
import customerStore from "../../store/customerStore";
import alertStore from "../../store/alertStore";
import timestampStore from "../../store/timestampStore";
import { idPatternTW, calculateAge } from "../../service/validation";
import { sanitizeError, internationalMobilePrefix, errorKeyNavigateSystemErrorPage, getCurrentTimestamp } from "../../app.utils";
import errorTranslationKeyStore from "../../store/errorTranslationKeyStore";
import DateInputField from "../../components/form/date-field";
import SelectField from "../../components/form/select-field";

const PageAccount = () => {
    const minDateOfBirth = calculateAge();
    const [showSMSModal, setShowSMSModal] = useState(false);
    const [responseOtpId, setResponseOtpId] = useState("");

    const intl = useIntl();
    const messages = intl.messages;
    const errors = {
        customerFullName: messages["account.form.fullNameField.required"],
        customerFullNameOnlyZhAllowed: messages["account.form.fullNameField.onlyZhAllowed"],
        customerNationalId: messages["account.form.nationalIdField.required"],
        customerDateOfBirth: messages["account.form.dateOfBirthField.required"],
        customerMobileNumber: messages["account.form.mobileNumberField.required"],
        customerAreaCode: messages["account.form.areaCodeField.required"],
        customerResidentialNumber: messages["account.form.residentialNumberField.required"],
        customerEmail: messages["account.form.emailField.required"],
        tncAgreed: messages["account.form.tnc.required"],
        tncAgreedAllAccount: messages["account.form.tnc.agreeAllRequired"],
    }

    const formik = useFormik({
        initialValues: {
            customerFullName: customerStore.customerFullName || "",
            customerNationalId: customerStore.customerNationalId || "",
            customerDateOfBirth: customerStore.customerDateOfBirth || "",
            customerMobileNumber: customerStore.customerMobileNumber || "",
            customerAreaCode: customerStore.customerAreaCode || "",
            customerResidentialNumber: customerStore.customerResidentialNumber || "",
            customerEmail: customerStore.customerEmail || "",
            tncAgreed: false,
            tncAgreedAllAccount: false
        },
        validationSchema: Yup.object({
            customerFullName: Yup.string()
                .min(1, errors.customerFullName)
                .max(40)
                .matches(/^\p{Script=Han}+$/u,errors.customerFullNameOnlyZhAllowed)
                .required(errors.customerFullName),
            customerNationalId: Yup.string()
                .matches(idPatternTW, errors.customerNationalId)
                .required(errors.customerNationalId),
            customerDateOfBirth: Yup.string().test(
                "customerDateOfBirth",
                errors.customerDateOfBirth,
                value => {
                    if(!value) return false;
                    var date = new Date(value);
                    return date <= minDateOfBirth ? true : false;
                }
            ).nullable(),
            customerMobileNumber: Yup.string()
                .min(10, errors.customerMobileNumber)
                .max(10, errors.customerMobileNumber)
                .required(errors.customerMobileNumber),
            customerAreaCode: Yup.string()
                .required(errors.customerAreaCode),                
            customerResidentialNumber: Yup.string()
                .matches(/^\d+$/, errors.customerResidentialNumber)
                .required(errors.customerResidentialNumber),
            customerEmail: Yup.string()
                .email(errors.customerEmail)
                .required(errors.customerEmail),
            tncAgreed: Yup.boolean()
                .oneOf([true], errors.tncAgreed).required(),
            tncAgreedAllAccount: Yup.boolean()
                .oneOf([true], errors.tncAgreedAllAccount).required()
        }),
        onSubmit: (values, action) => {
            action.setSubmitting(true);
            customerStore.updateCustomerDetailAccount(values);
            onHandleRequestOtp();
        },
    });

    const onHandleRequestOtp = async () => {
        try {
            const payload = {
                phoneNumber: internationalMobilePrefix(customerStore.customerMobileNumber),
                countryCode: "TW",
                languageCode: "zh"
            };
            const response = await api.post.requestOtp(payload);
            setResponseOtpId(response.data.otpId);
            openModal();
        }
        catch(error) {
            const errorTranslationKey = error?.response?.data?.translationKey;

            alertStore.updateAlertMessage(sanitizeError(error));
            if (errorTranslationKey) {
                errorTranslationKeyStore.updateTranslationKey(errorTranslationKey);
                errorKeyNavigateSystemErrorPage(errorTranslationKey);
            }
        }
    }

    const onHandleValidateOtp = async (values, enableSubmit) => {
        try {
            const payload = {
                otpId: responseOtpId,
                otpCode: values.otpInput,
            };
            await api.post.validateOtp(payload);
            goToReviewOrderPage();
        }
        catch(error) {
            const errorTranslationKey = error?.response?.data?.translationKey;

            alertStore.updateAlertMessage(sanitizeError(error));
            if (errorTranslationKey) {
                errorTranslationKeyStore.updateTranslationKey(errorTranslationKey);
                errorKeyNavigateSystemErrorPage(errorTranslationKey);
            }
            
            if(enableSubmit) {
                enableSubmit();
            }
        }
    }

    const closeModal = () => {
        setShowSMSModal(false);
        formik.setSubmitting(false);
    }

    const openModal = () => {
        setShowSMSModal(true);
    };

    const goToReviewOrderPage = () => {
        navigate(constants.ROUTES.REVIEW_ORDER);
    };

    useEffect(() => {
        if (formik.values.tncAgreed) {
            timestampStore.updateTncAgreedTimestamp(getCurrentTimestamp());
        }
    }, [formik.values.tncAgreed]);

    useEffect(() => {
        if (formik.values.tncAgreedAllAccount) {
            timestampStore.updateTncAgreedAllAccountTimestamp(getCurrentTimestamp());
        }
    }, [formik.values.tncAgreedAllAccount]);

    return (
        <Fragment>
            {showSMSModal && (
                <AppSmsModal
                    requestOtp={onHandleRequestOtp}
                    closeModal={closeModal}
                    onSubmit={onHandleValidateOtp}
                />
            )}

            <AppLayout hasHeader={true} hasHeaderLogo={true} hasFooter={true} isBackBtn={true} prevUrl={constants.ROUTES.PLANS}>

                <AppStepsIndicator step="2" />

                <div className="app-page page-account">

                    <div className="account__container container">

                        <form
                            className="account__form"
                            noValidate
                            autoComplete="off"
                            onSubmit={formik.handleSubmit}
                        >

                            <InputField
                                label={messages["account.form.fullNameField.label"]}
                                placeholder={messages["account.form.fullNameField.placeholder"]}
                                name="customerFullName"
                                formik={formik}
                            />

                            <InputField
                                label={messages["account.form.nationalIdField.label"]}
                                placeholder={messages["account.form.nationalIdField.placeholder"]}
                                name="customerNationalId"
                                formik={formik}
                            />

                            <DateInputField
                                label={messages["account.form.dateOfBirthField.label"]}
                                name="customerDateOfBirth"
                                formik={formik}
                                maxDate={minDateOfBirth}
                            />

                            <InputField
                                label={messages["account.form.mobileNumberField.label"]}
                                placeholder={messages["account.form.mobileNumberField.placeholder"]}
                                name="customerMobileNumber"
                                formik={formik}
                            />

                            <div className="form-row">
                                <div className="col-6">
                                    <SelectField
                                        label={messages["account.form.residentialNumberField.label"]}
                                        name="customerAreaCode"
                                        options={constants.AREA_CODE}
                                        formik={formik}
                                        placeholder={messages["account.form.areaCodeField.placeholder"]}
                                    />
                                </div>
                                <div className="col-6">
                                    <InputField
                                        placeholder={messages["account.form.residentialNumberField.placeholder"]}
                                        name="customerResidentialNumber"
                                        formik={formik}
                                    />
                                </div>
                            </div>

                            <InputField
                                label={messages["account.form.emailField.label"]}
                                placeholder={messages["account.form.emailField.placeholder"]}
                                name="customerEmail"
                                formik={formik}
                            />

                            <CheckboxField
                                name="tncAgreed"
                                formik={formik}
                                text={[
                                    messages["account.form.tnc.text"],
                                    " ",
                                    <Link to={constants.ROUTES.TNC01} rel="noreferrer">{messages["account.form.tnc.documents.0.label"]}</Link>,
                                    ", ",
                                    <Link to={constants.ROUTES.TNC02} rel="noreferrer">{messages["account.form.tnc.documents.1.label"]}</Link>,
                                    ", ",
                                    <Link to={constants.ROUTES.TNC03} rel="noreferrer">{messages["account.form.tnc.documents.2.label"]}</Link>,
                                    ", ",
                                    <Link to={constants.ROUTES.TNC04} rel="noreferrer">{messages["account.form.tnc.documents.3.label"]}</Link>,
                                    ", ",
                                    <Link to={constants.ROUTES.TNC05} rel="noreferrer">{messages["account.form.tnc.documents.4.label"]}</Link>,
                                ]}

                            />

                            <CheckboxField
                                name="tncAgreedAllAccount"
                                formik={formik}
                                text={messages["account.form.tnc.agreeAll"]}
                            />

                            <p className="btn-container btn-container--center">
                                <button className="btn btn-lg btn-primary" type="submit" disabled={formik.isSubmitting || !formik.isValid}>
                                    <FormattedMessage id="account.form.cta" />
                                </button>
                            </p>

                        </form>

                    </div>

                </div>

            </AppLayout>

        </Fragment>
    );
};

export default PageAccount;
